body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: "5px";
}

code {
  font-family: "Mulish", sans-serif;
  letter-spacing: "5px";
}

